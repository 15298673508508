@import 'colors';

.social-icon {

}

.social-icon:hover {
  filter: drop-shadow(0 0 3px $fg-color);
}


