@font-face {
    font-family: 'Poppins-Regular';
    src: url('../../public/fonts/Poppins/Poppins-Regular.ttf') format('truetype')
}


@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../public/fonts/Poppins/Poppins-Bold.ttf') format('truetype')
}


@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url('../../public/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype')
}


.text-regular {
    font-family: Poppins-Regular, serif;
}

.text-bold {
    font-family: Poppins-Bold, serif;
}

.text-extrabold {
    font-family: Poppins-ExtraBold, serif;
}


