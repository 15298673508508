@import 'colors';


.image-me {
  max-width: 82% !important;
}

.image-project {
  max-height: 60vh;
}

@media screen and (max-width: 1536px) {
  .image-me {
    max-width: 60% !important;
  }
  .image-project {
    max-height: 55vh;
  }
}

@media screen and (max-width: 1200px) {
  .image-me {
    max-width: 71% !important;
  }
}

