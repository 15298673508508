@import 'colors';


.project-card {

}

.project-card:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 5px $fg-color);
}

.project-card-image {
  height: 16rem;
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .project-card-image {
    height: 14rem;
  }
}
