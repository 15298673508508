$bg-color: #221F1F;
$fg-color: #FFFFFF;

$theme-colors: (
        'dark': $bg-color,
        'light': $fg-color
);

$body-bg: $bg-color;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
